<template>
  <div id="about-me">
    <div id="about-me-headshot-wrapper">
      <img id="about-me-headshot"
           :src="headshot"
           loading="lazy"
           alt="Headshot"/>
    </div>

    <div id="about-me-description">
      <h1 id="about-me-title">
        {{ aboutMe.title }}
      </h1>

      <p id="about-me-body">
        {{ aboutMe.body }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'About',

    // head: {
    //   title: 'About — Adam Chaarawi',
    //   meta: [
    //     {
    //       name: 'description',
    //       id: 'desc',
    //       content: "I'm Adam Chaarawi! I'm a New York and Los Angeles based actor. Check out my site :)",
    //     }
    //   ],
    // },

    data() {
      const yearDelta = Math.floor((new Date() - new Date(2021, 2, 1)) / (365 *1000*60*60*24));
      return {
        aboutMe: {
          title: "Hey! I'm Adam.",
          body: "I'm a Lebanese-American actor based in Los Angeles and New York City (yep bi-coastal). " +
            `I grew up in LA, but moved to NYC about ${yearDelta} years ago and travel between the cities very often. ` +
            "I've done the majority of my training at the T. Schreiber Studio in NYC.",
        },
        headshot: 'https://res.cloudinary.com/dt7fbptp2/image/upload/v1704308633/personal/headshot-neck-square_uc6chb.jpg',
        // headshot: require('@/assets/headshot-neck-square.jpeg'),
      }
    }
  }
</script>

<style scoped>
  #about-me {
    display: flex;
    flex-direction: row-reverse;
    margin: 0 auto;
    width: fit-content;
    justify-content: space-between;
    max-width: 1200px;
    flex-wrap: wrap;
  }

  #about-me-headshot-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 45%;
    min-width: 300px;
  }

  #about-me-headshot {
    width: 100%;
    max-height: 600px;
    margin-bottom: auto;
    object-fit: contain;
  }

  #about-me-description {
    width: 45%;
    text-align: left;
    color: black;
  }

  #about-me-title {
    margin-top: 0;
    margin-bottom: 30px;
    font-weight: 500;
  }

  #about-me-body {
    font-size: 1.35em;
    line-height: 1.8;
    font-weight: normal;
    color: #454545;
  }

  @media only screen and (max-width: 700px) {
    #about-me-description {
      width: 100%;
    }

    #about-me-headshot-wrapper {
      width: 100%;
      justify-content: center;
      margin: 0 0 30px 0;
    }
  }

  @media only screen and (max-width: 475px) {
    #about-me-headshot {
      max-height: 400px;
    }
  }
</style>